import { Typography } from "@mui/material";
import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import { useAuth } from "../context/AuthContext";

export default function UserProfilePage() {
  const { userIsAuthenticated } = useAuth();

  if (userIsAuthenticated()) {
    return (
      <>
        <MDBContainer className="d-flex justify-content-center align-items-center text-center mt-5">
          <Typography
            variant="h4"
            className="d-flex justify-content-center align-items-center text-center mt-5 text-white"
          >
            Тут буде сторінка профайлу користувача
          </Typography>
        </MDBContainer>
      </>
    );
  } else {
    return (
      <>
        <MDBContainer className="d-flex justify-content-center align-items-center text-center mt-5">
          <Typography
            variant="h4"
            className="d-flex justify-content-center align-items-center text-center mt-5"
          >
            Для доступу необхідно виконати авторизацію
          </Typography>
        </MDBContainer>
      </>
    );
  }
}
