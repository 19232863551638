import React, { useState } from "react";
import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalDialog,
  MDBModalContent,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBBtn,
} from "mdb-react-ui-kit";
import CreateCandidateService from "../api/candidate/CreateCandidateService";
import NumberFormat from "react-number-format";
import CvTable from "../tables/CvTable";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import uk from "date-fns/locale/uk";
import { useSnackbar } from "notistack";
import { useAuth } from "../context/AuthContext";
import getHostName from "../api/config/config";

export default function CandidateCard(props) {
  const [varyingModal, setVaryingModal] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const toggleShow = () => setVaryingModal(!varyingModal);
  const { enqueueSnackbar } = useSnackbar();
  const { row, candidateId } = props;
  const candidateExists = candidateId !== undefined && candidateId !== "";
  const { getUser } = useAuth();

  const [info, setInfo] = useState({
    id: candidateId !== undefined ? candidateId : "",
    name: row !== undefined ? row.name : "",
    vacancy: row !== undefined ? row.vacancy : "",
    status: row !== undefined ? row.status : "",
    recruiter:
      row !== undefined && candidateId !== undefined
        ? row.recruiter
        : getUser().data.name,
    linkedIn: row !== undefined ? row.linkedIn : "",
    email: row !== undefined ? row.email : "",
    phoneNumber: row !== undefined ? row.phoneNumber : "",
    comment: row !== undefined ? row.comment : "",
    salaryExpectations: row !== undefined ? row.salaryExpectations : "",
    vacancyStage: row !== undefined ? row.vacancyStage : "",
    firstWorkDay: row !== undefined ? row.firstWorkDay : "",
  });

  const modalTitle =
    row !== undefined
      ? `Картка кандидата з ID ${candidateId}`
      : "Картка кандидата";
  const saveButtonText =
    row !== undefined ? "Зберегти інформацію" : "Додати кандидата";

  const vacancyStages = [
    {
      value: "Відкрита",
      label: "Відкрита",
    },
    {
      value: "Закрита",
      label: "Закрита",
    },
    {
      value: "На паузі",
      label: "На паузі",
    },
  ];

  function getStatuses() {
    if (statuses.length === 0) {
      fetch(getHostName() + "/api/v1/status/get-all-statuses")
        .then((response) => response.json())
        .then((data) => {
          setStatuses(data);
        });
    }
  }

  function showAlert(res) {
    if (res === 200) {
      enqueueSnackbar("Інформацію про кандидата упішно збережено", {
        variant: "success",
      });
    } else if (res === 409) {
      enqueueSnackbar("Кандидат з email " + info.email + " вже існує!", {
        variant: "error",
      });
    } else {
      alert(res, {
        variant: "error",
      });
    }
  }

  function CandidateButtons() {
    return (
      <>
        <Button
          size="small"
          onClick={() => {
            toggleShow();
            getStatuses();
          }}
          disabled={!candidateExists}
          className="toolbar-button"
        >
          <MDBIcon fas icon="edit" className="me-2"></MDBIcon>
          Редагувати
        </Button>
        <Button
          size="small"
          onClick={() => {
            toggleShow();
            getStatuses();
          }}
          className="toolbar-button"
        >
          <MDBIcon fas icon="plus" className="me-2"></MDBIcon>
          Додати
        </Button>
      </>
    );
  }

  return (
    <>
      <CandidateButtons></CandidateButtons>
      <MDBModal
        staticBackdrop
        show={varyingModal}
        setShow={setVaryingModal}
        tabIndex="-1"
        className="candidateModal"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader className="bg-dark text-white">
              <MDBModalTitle>{modalTitle}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={function() {
                  setVaryingModal(!varyingModal);
                  window.location.reload();
                }}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="bg-dark text-white">
              <form>
                <div className="row">
                  <div className="col mb-3 ms-2">
                    <TextField
                      label="ПІБ"
                      size="small"
                      fullWidth
                      value={info.name}
                      className="input-white"
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col mb-3 me-2">
                    <TextField
                      value={info.vacancy}
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          vacancy: e.target.value,
                        })
                      }
                      className="input-white"
                      size="small"
                      fullWidth
                      label="Вакансія"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3 ms-2 text-start">
                    <TextField
                      select
                      label="Статус"
                      size="small"
                      fullWidth
                      value={info.status}
                      className="input-white"
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          status: e.target.value,
                        })
                      }
                    >
                      {statuses.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col mb-3 me-2">
                    <TextField
                      value={info.recruiter}
                      className="input-white"
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          recruiter: e.target.value,
                        })
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Рекрутер"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3 ms-2">
                    <TextField
                      value={info.linkedIn}
                      className="input-white"
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          linkedIn: e.target.value,
                        })
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="LinkedIn"
                    />
                  </div>
                  <div className="col mb-3 me-2">
                    <TextField
                      value={info.email}
                      className="input-white"
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          email: e.target.value,
                        })
                      }
                      size="small"
                      fullWidth
                      label="Email"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3 ms-2">
                    <NumberFormat
                      customInput={TextField}
                      className="input-white"
                      fullWidth
                      size="small"
                      format="+38(###)###-##-##"
                      mask="_"
                      allowEmptyFormatting
                      value={info.phoneNumber}
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          phoneNumber: e.target.value,
                        })
                      }
                      label="Телефон"
                    />
                  </div>
                  <div className="col mb-3 me-2">
                    <TextField
                      value={info.salaryExpectations}
                      className="input-white"
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          salaryExpectations: e.target.value,
                        })
                      }
                      size="small"
                      fullWidth
                      label="Очікування по ЗП"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3 ms-2 text-start">
                    <TextField
                      select
                      label="Стадія вакансії"
                      className="input-white"
                      size="small"
                      fullWidth
                      value={info.vacancyStage}
                      onChange={(e) =>
                        setInfo({
                          ...info,
                          vacancyStage: e.target.value,
                        })
                      }
                    >
                      {vacancyStages.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="col mb-3 me-2">
                    <LocalizationProvider
                      adapterLocale={uk}
                      dateAdapter={AdapterDateFns}
                    >
                      <DesktopDatePicker
                        label="Перший робочий день"
                        inputFormat="dd.MM.yyyy"
                        className="input-white"
                        value={info.firstWorkDay}
                        onChange={(value) =>
                          setInfo({
                            ...info,
                            firstWorkDay: value,
                          })
                        }
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <TextField
                              label="Перший робочий день"
                              className="input-white"
                              size="small"
                              fullWidth
                              ref={inputRef}
                              {...inputProps}
                            />
                            {InputProps.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="mb-2 ms-2 me-2">
                  <TextField
                    value={info.comment}
                    className="input-white"
                    onChange={(e) =>
                      setInfo({
                        ...info,
                        comment: e.target.value,
                      })
                    }
                    label="Коментар"
                    multiline
                    size="small"
                    fullWidth
                    maxRows={10}
                  />
                </div>
                <CvTable candidateId={info.id}></CvTable>
                <MDBModalFooter className="d-flex justify-content-between align-items-center mt-3 pb-0">
                  <MDBBtn
                    outline
                    color="dark"
                    type="button"
                    className="border-white text-white"
                    onClick={function() {
                      setVaryingModal(!varyingModal);
                      window.location.reload();
                    }}
                  >
                    Відхилити
                  </MDBBtn>
                  <MDBBtn
                    type="button"
                    color="warning"
                    onClick={async function() {
                      const res = await CreateCandidateService(info);
                      if (res === 409) {
                        showAlert(res);
                      } else {
                        toggleShow();
                        setTimeout(function() {
                          window.location.reload();
                        }, 1000);
                        showAlert(res);
                      }
                    }}
                  >
                    {saveButtonText}
                  </MDBBtn>
                </MDBModalFooter>
              </form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
