import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./modals/Login";
import { AuthProvider } from "./context/AuthContext";
import Homepage from "./pages/Homepage";
import StatusPage from "./pages/StatusPage";
import SettingsPage from "./pages/SettingsPage";
import { SnackbarProvider } from "notistack";

import "./styles/generic.scss";
import "./styles/CandidateTable.scss";
import "./styles/Login.scss";
import UserProfilePage from "./pages/UserProfilePage";

function App() {
  return (
    <AuthProvider>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Homepage />} />
              <Route path="login" element={<Login />} />
              <Route path="user/profile" element={<UserProfilePage />} />
              <Route path="table/settings" element={<SettingsPage />} />
              <Route path="table/candidate/status" element={<StatusPage />} />
            </Route>
          </Routes>
        </Router>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;
