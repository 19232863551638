import {
  MDBBtn,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBTypography,
} from "mdb-react-ui-kit";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FolderService } from "../api/folder/FolderService";

export default function ButtonFolderDelete(props) {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const { id, setAsyncData } = props;
  const { enqueueSnackbar } = useSnackbar();

  function showAlert(res) {
    if (res === 200) {
      enqueueSnackbar("Папку з ID " + id + " упішно видалено", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(res, {
        variant: "error",
      });
    }
  }

  return (
    <>
      <MDBBtn
        block
        color="warning"
        type="button"
        size="small"
        onClick={toggleShow}
        className="toolbar-button mt-3"
      >
        <MDBIcon
          size="lg"
          icon="trash-alt"
          className="datatable-disable-onclick me-2"
        />
        Видалити
      </MDBBtn>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog className="bg-dark text-white">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setBasicModal(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer fluid>
                <MDBTypography tag="h5">
                  Ви дійсно бажаєте видалити папку з ID
                  <br />
                  {id} ?
                </MDBTypography>
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter className="d-flex justify-content-between align-items-center">
              <MDBBtn
                outline
                color="dark"
                type="button"
                className="border-white text-white"
                onClick={() => setBasicModal(false)}
              >
                Відхилити
              </MDBBtn>
              <MDBBtn
                type="button"
                color="warning"
                className="mx-2"
                onClick={async function(event) {
                  event.preventDefault();
                  let res = await FolderService.deleteFolder(id);
                  toggleShow();
                  showAlert(res);
                  FolderService.getFolderTree().then((data) => {
                    setTimeout(() => {
                      setAsyncData({
                        folders: data,
                      });
                    }, 100);
                  });
                }}
              >
                Видалити
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
