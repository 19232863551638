import React, { useState } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
} from "mdb-react-ui-kit";
import Login from "../modals/Login";

export default function Header() {
  const [showBasic, setShowBasic] = useState(false);

  return (
    <MDBNavbar
      expand="lg"
      dark
      bgColor="dark"
      className="h-1 p-1"
      fixed="top"
    >
      <MDBNavbarBrand href="/" className="ms-5">
        <img
          src="/qax-logo.svg"
          alt="qax-logo.svg"
          loading="lazy"
          height="33"
          className="ms-5"
        />
        HR search
      </MDBNavbarBrand>

      <MDBNavbarToggler
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => setShowBasic(!showBasic)}
      >
        <MDBIcon icon="bars" fas />
      </MDBNavbarToggler>

      <MDBCollapse navbar show={showBasic}>
        <MDBNavbarNav className="d-flex justify-content-end align-items-center">
          <MDBNavbarItem>
            <MDBNavbarLink active aria-current="page" href="/">
              Головна
            </MDBNavbarLink>
          </MDBNavbarItem>
          <Login></Login>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
}
