import axios from "axios";
import getHostName from "../config/config";

export default async function GetCvList() {
  try {
    const response = await axios.get(getHostName() + "/api/v1/cv/get-all-cvs");
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
}
