import React, { useState } from "react";
import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalDialog,
  MDBModalContent,
  MDBModalFooter,
  MDBModalHeader,
  MDBBtn,
  MDBContainer,
  MDBTypography,
} from "mdb-react-ui-kit";
import DeleteStatusService from "../api/status/DeleteStatusService";
import { useSnackbar } from "notistack";

export default function HandlerStatusDelete(props) {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const { id, value, setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();

  function showAlert(res) {
    if (res === 200) {
      enqueueSnackbar("Статус " + value + " упішно видалено", {
        variant: "success",
      });
    } else if (res === 404) {
      enqueueSnackbar("Статус " + value + " не знайдено", {
        variant: "error",
      });
    } else {
      enqueueSnackbar(res, {
        variant: "error",
      });
    }
  }

  return (
    <>
      <MDBBtn
        tag="a"
        color="none"
        onClick={toggleShow}
        className="delete-button text-white"
      >
        <MDBIcon
          size="lg"
          icon="trash-alt"
          className="datatable-disable-onclick"
        />
      </MDBBtn>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog className="bg-dark text-white">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setBasicModal(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer fluid>
                <MDBTypography tag="h5">
                  Ви дійсно бажаєте видалити статус
                  <br />"{value}" ?
                </MDBTypography>
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter className="d-flex justify-content-between align-items-center">
              <MDBBtn
                outline
                color="dark"
                type="button"
                className="border-white text-white"
                onClick={() => setBasicModal(false)}
              >
                Відхилити
              </MDBBtn>
              <MDBBtn
                type="button"
                color="warning"
                className="mx-2"
                onClick={async function(event) {
                  event.preventDefault();
                  let res = await DeleteStatusService(id);
                  toggleShow();
                  showAlert(res);
                  setTimeout(function() {
                    setLoading(true);
                  }, 1000);
                }}
              >
                Видалити
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
