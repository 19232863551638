import {
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import getHostName from "../api/config/config";
import HandlerStatusDelete from "../handlers/HandlerStatusDelete";
import SaveStatus from "../components/SaveStatus";

export default function StatusTable() {
  const [loading, setLoading] = useState(true);
  const [statusExists, setStatusExists] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [asyncData, setAsyncData] = useState({
    columns: [
      { label: "Статус", field: "value" },
      { label: "Дії", field: "actions" },
    ],
    rows: [],
  });

  function getStatuses() {
    fetch(getHostName() + "/api/v1/status/get-all-statuses")
      .then((response) => response.json())
      .then((data) =>
        setTimeout(() => {
          setAsyncData({
            columns: asyncData.columns,
            rows: data.map((row) => ({
              ...row,
              actions: (
                <>
                  <MDBIcon size="lg" fas icon="edit" className="me-2"></MDBIcon>
                  <HandlerStatusDelete
                    id={row.id}
                    value={row.value}
                    setLoading={setLoading}
                  ></HandlerStatusDelete>
                </>
              ),
            })),
          });
        }, 1000)
      );
  }

  useEffect(() => {
    if (loading) {
      setLoadingMessage("Завантаження...");
      getStatuses();
      setTimeout(() => {
        if (asyncData.rows.length === 0) {
          setLoadingMessage("Статуси не знайдено");
          setStatusExists(false);
        } else {
          setStatusExists(true);
        }
        setLoading(false);
      }, 2000);
    } else {
      setStatusExists(true);
      setLoading(false);
    }
  }, [loading, asyncData.rows]);

  return (
    <div className="row m-2 text-center">
      <MDBTable bordered small className="text-white">
        <MDBTableHead>
          <tr>
            <th scope="row">Статус</th>
            <th scope="col">Дії</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {(loading || !statusExists) && (
            <tr>
              <td colSpan={3}>{loadingMessage}</td>
            </tr>
          )}
          {!loading &&
            asyncData.rows.map((row) => (
              <tr key={row.id}>
                <td>{row.value}</td>
                <td>{row.actions}</td>
              </tr>
            ))}
        </MDBTableBody>
      </MDBTable>
      <div className="row ms-0 p-0">
        <SaveStatus setLoading={setLoading}></SaveStatus>
      </div>
    </div>
  );
}
