import axios from "axios";
import getHostName from "../config/config";

export default async function DeleteCvService(id) {
  try {
    const response = await axios.delete(
      getHostName() + "/api/v1/cv/delete/" + id
    );
    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
}
