import React, { Component, useState } from "react";
import AuthContext from "../context/AuthContext";
import { LoginService } from "../api/login/LoginService";
import { parseJwt, handleLogError } from "../api/login/LoginService";
import {
  MDBInput,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBNavbarItem,
  MDBNavbarLink,
} from "mdb-react-ui-kit";
import AccountMenu from "../components/AccountMenu";

class Login extends Component {
  static contextType = AuthContext;

  state = {
    username: "",
    password: "",
    isLoggedIn: false,
    isError: false,
  };

  componentDidMount() {
    const Auth = this.context;
    const isLoggedIn = Auth.userIsAuthenticated();
    this.setState({ isLoggedIn });
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { username, password } = this.state;
    if (!(username && password)) {
      this.setState({ isError: true });
      return;
    }

    LoginService.authenticate(username, password)
      .then((response) => {
        const { accessToken } = response.data;
        const data = parseJwt(accessToken);
        const user = { data, accessToken };

        const Auth = this.context;
        Auth.userLogin(user);

        this.setState({
          username: "",
          password: "",
          isLoggedIn: true,
          isError: false,
        });
      })
      .catch((error) => {
        handleLogError(error);
        this.setState({ isError: true });
      });
  };

  getReferer = () => {
    const locationState = this.props.location;
    return locationState && locationState.referer ? locationState.referer : "/";
  };

  LoginModal = () => {
    const [centredModal, setCentredModal] = useState(false);
    const toggleShow = () => setCentredModal(!centredModal);

    return (
      <>
        <MDBNavbarItem>
          <MDBBtn
            outline
            color="dark"
            className="ms-5 me-5 text-center d-none d-lg-block border-white text-white"
            type="button"
            size="mx-2"
            onClick={toggleShow}
          >
            Увійти
          </MDBBtn>
          <MDBNavbarLink
            className="ms-5 me-5 text-center d-block d-sm-none border-white text-white"
            onClick={toggleShow}
          >
            Увійти
          </MDBNavbarLink>
        </MDBNavbarItem>

        <MDBModal
          staticBackdrop
          show={centredModal}
          setShow={setCentredModal}
          tabIndex="-1"
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader className="d-flex justify-content-center bg-dark text-white">
                <MDBModalTitle>Авторизація</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={() => setCentredModal(false)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody className="bg-dark text-white">
                <MDBContainer>
                  <form onSubmit={this.handleSubmit}>
                    <MDBInput
                      className="mb-3"
                      type="email"
                      id="email"
                      label="Логін"
                      required
                      name="username"
                      onChange={this.handleInputChange}
                    />
                    <MDBInput
                      className="mb-3"
                      type="password"
                      id="password"
                      label="Пароль"
                      required
                      name="password"
                      onChange={this.handleInputChange}
                    />
                    <MDBBtn type="submit" className="mb-3 submit-button" block>
                      Увійти
                    </MDBBtn>
                  </form>
                </MDBContainer>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    );
  };

  UserProfileButton = () => {
    return (
      <>
        <MDBNavbarItem className="ms-5">
          <AccountMenu></AccountMenu>
        </MDBNavbarItem>
      </>
    );
  };

  render() {
    const { isLoggedIn } = this.state;

    if (isLoggedIn) {
      return <this.UserProfileButton></this.UserProfileButton>;
    } else {
      return <this.LoginModal></this.LoginModal>;
    }
  }
}

export default Login;
