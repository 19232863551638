import React, { useState } from "react";
import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalDialog,
  MDBModalContent,
  MDBModalFooter,
  MDBModalHeader,
  MDBBtn,
} from "mdb-react-ui-kit";
import DeleteCandidateService from "../api/candidate/DeleteCandidateService";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

export default function HandlerCandidateDelete(props) {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const { id, name } = props;
  const { enqueueSnackbar } = useSnackbar();
  const candidateExists = id !== undefined && id !== "";

  function showAlert(res) {
    if (res === 200) {
      enqueueSnackbar(
        "Кандидата " + name + " з ID " + id + " упішно видалено",
        {
          variant: "success",
        }
      );
    } else if (res === 404) {
      enqueueSnackbar("Кандидата " + name + " з ID " + id + " не знайдено", {
        variant: "error",
      });
    } else {
      enqueueSnackbar(res, {
        variant: "error",
      });
    }
  }

  return (
    <>
      <Button
        size="small"
        onClick={toggleShow}
        disabled={!candidateExists}
        className="toolbar-button"
      >
        <MDBIcon fas icon="trash-alt" className="me-2"></MDBIcon>
        Видалити
      </Button>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog className="bg-dark text-white">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setBasicModal(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <h5>Ви дійсно бажаєте видалити кандидата</h5>
              <h5>
                {name} з ID {id} ?
              </h5>
            </MDBModalBody>
            <MDBModalFooter className="d-flex justify-content-between align-items-center">
              <MDBBtn
                outline
                color="dark"
                type="button"
                className="border-white text-white"
                onClick={() => setBasicModal(false)}
              >
                Відхилити
              </MDBBtn>
              <MDBBtn
                type="button"
                color="warning"
                className="mx-2"
                onClick={async function() {
                  let res = await DeleteCandidateService(id);
                  toggleShow();
                  showAlert(res);
                  setTimeout(function() {
                    window.location.reload();
                  }, 1000);
                }}
              >
                Видалити
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
