import React from "react";
import { MDBFooter, MDBBtn, MDBIcon, MDBContainer } from "mdb-react-ui-kit";

export default function Footer() {
  return (
    <MDBFooter className="bg-dark d-flex justify-content-between align-items-center text-center p-2">
      <MDBContainer></MDBContainer>
      <MDBContainer className="text-center text-light">2022 - 2023</MDBContainer>
      <MDBContainer>
        <MDBBtn
          tag="a"
          href="https://qax.com.ua/"
          target="none"
          color="none"
          className="me-3"
        >
          <img src="/qax-logo.ico" alt="qax-logo.jpg" loading="lazy" />
        </MDBBtn>
        <MDBBtn
          tag="a"
          href="https://www.facebook.com/qaxquality/"
          target="none"
          color="none"
          style={{ color: "#0D2149" }}
        >
          <MDBIcon fab icon="facebook-f" />
        </MDBBtn>
        <MDBBtn
          tag="a"
          href="https://www.linkedin.com/company/qax/"
          target="none"
          color="none"
          className="ms-3"
          style={{ color: "#0D2149" }}
        >
          <MDBIcon fab icon="linkedin-in" />
        </MDBBtn>
      </MDBContainer>
    </MDBFooter>
  );
}
