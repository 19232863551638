import { Typography } from "@mui/material";
import {
  MDBContainer,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function SettingsPage() {
  const { userIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (userIsAuthenticated()) {
    return (
      <>
        <Typography
          variant="h5"
          className="d-flex justify-content-center align-items-center text-center text-white mt-2"
        >
          Налаштування таблиць
        </Typography>
        <MDBContainer className="d-flex justify-content-center align-items-center text-center mt-2">
          <MDBTable bordered small className="text-white">
            <MDBTableHead>
              <tr>
                <th scope="col">Назва таблиці</th>
                <th scope="col">Назва колонки</th>
                <th scope="col">Дії</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td>Кандидати</td>
                <td>Статуси</td>
                <td
                  onClick={() => {
                    navigate("/table/candidate/status");
                  }}
                >
                  Налаштувати
                </td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        </MDBContainer>
      </>
    );
  } else {
    return (
      <>
        <MDBContainer className="d-flex justify-content-center align-items-center text-center mt-5">
          <Typography
            variant="h4"
            className="d-flex justify-content-center align-items-center text-center mt-5"
          >
            Для доступу необхідно виконати авторизацію
          </Typography>
        </MDBContainer>
      </>
    );
  }
}
