import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import { Box, Button, Container } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { alpha, styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import React, { useState } from "react";
import { FolderService } from "../api/folder/FolderService";
import ButtonFolderCreate from "./ButtonFolderCreate";
import ButtonFolderDelete from "./ButtonFolderDelete";
import ButtonFolderEdit from "./ButtonFolderEdit";
import ButtonTableCreate from "./ButtonTableCreate";

export default function CustomTreeView() {
  const [toggleDrawer, setToggleDrawer] = React.useState(false);
  const toggleShow = () => setToggleDrawer(!toggleDrawer);
  const [asyncData, setAsyncData] = useState({
    folders: [],
    tables: [],
  });
  const [selectedNode, setSelectedNode] = useState("");

  function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }

  function CloseSquare(props) {
    return (
      <SvgIcon
        className="close"
        fontSize="inherit"
        style={{ width: 14, height: 14 }}
        {...props}
      >
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }

  const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
    ({ theme }) => ({
      [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
          opacity: 0.3,
        },
      },
      [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      },
    })
  );

  const renderTree = (folders) => {
    if (asyncData.folders.length !== 0) {
      return (
        <StyledTreeItem
          key={folders.id}
          nodeId={folders.id.toString()}
          label={folders.label}
        >
          {Array.isArray(folders.children)
            ? folders.children.map((folder) => renderTree(folder))
            : null}
        </StyledTreeItem>
      );
    }
  };

  return (
    <>
      <div>
        <Button
          className="toolbar-button"
          onClick={() => {
            toggleShow();
            FolderService.getFolderTree().then((data) => {
              setTimeout(() => {
                setAsyncData({
                  folders: data,
                });
              }, 100);
            });
          }}
        >
          <MenuIcon className="me-2" />
          Меню
        </Button>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "#2c2a3f",
              color: "white",
            },
          }}
          open={toggleDrawer}
          onClose={toggleShow}
          variant="persistent"
        >
          <Box
            sx={{
              width: 500,
              marginTop: 2,
            }}
            role="presentation"
          >
            <div className="d-flex align-items-center justify-content-end text-white">
              <Button onClick={toggleShow}>
                <CloseIcon></CloseIcon>
              </Button>
            </div>
            <div
              className="d-flex align-items-stretch flex-column mb-3"
              style={{ height: "630px" }}
            >
              <div className="p-2">
                <TreeView
                  className="p-2"
                  aria-label="customized"
                  defaultExpanded={["1"]}
                  defaultCollapseIcon={<MinusSquare />}
                  defaultExpandIcon={<PlusSquare />}
                  defaultEndIcon={<CloseSquare />}
                  onNodeSelect={(event, nodeId) => {
                    setSelectedNode(nodeId);
                  }}
                  sx={{
                    height: "auto",
                    flexGrow: 1,
                    maxWidth: 500,
                    overflowY: "auto",
                    backgroundColor: "#3e3b5e",
                    marginTop: 2,
                    marginRight: 1,
                  }}
                >
                  {renderTree(asyncData.folders)}
                </TreeView>
              </div>
              <div className="mt-auto">
                <Container>
                  <ButtonFolderCreate
                    parentId={selectedNode}
                    setAsyncData={setAsyncData}
                  ></ButtonFolderCreate>
                  <ButtonTableCreate
                    setAsyncData={setAsyncData}
                  ></ButtonTableCreate>
                  <ButtonFolderEdit
                    folderId={selectedNode}
                    setAsyncData={setAsyncData}
                  ></ButtonFolderEdit>
                  <ButtonFolderDelete
                    id={selectedNode}
                    setAsyncData={setAsyncData}
                  ></ButtonFolderDelete>
                </Container>
              </div>
            </div>
          </Box>
        </Drawer>
      </div>
    </>
  );
}
