import { TextField } from "@mui/material";
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import SaveStatusService from "../api/status/SaveStatusService";

export default function SaveStatus(props) {
  const [varyingModal, setVaryingModal] = useState(false);
  const toggleShow = () => setVaryingModal(!varyingModal);
  const { statusId, setLoading } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState({
    id: statusId !== undefined ? statusId : "",
    value: "",
  });

  function showAlert(res) {
    if (res === 200) {
      enqueueSnackbar("Статус " + status.value + " упішно додано", {
        variant: "success",
      });
    } else if (res === 404) {
      enqueueSnackbar("Статус " + status.value + " не знайдено", {
        variant: "error",
      });
    } else {
      enqueueSnackbar(res, {
        variant: "error",
      });
    }
  }

  return (
    <>
      <MDBBtn
        block
        color="warning"
        type="button"
        size="small"
        onClick={toggleShow}
        className="toolbar-button"
      >
        <MDBIcon fas icon="plus" className="me-2"></MDBIcon>
        Додати новий статус
      </MDBBtn>
      <MDBModal
        staticBackdrop
        show={varyingModal}
        setShow={setVaryingModal}
        tabIndex="-1"
        className="candidateModal"
      >
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader className="bg-dark text-white">
              <MDBModalTitle>Налаштування статусів</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={function() {
                  setVaryingModal(!varyingModal);
                }}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="bg-dark text-white">
              <form>
                <div className="row">
                  <div className="col mb-3 ms-2">
                    <TextField
                      label="Статус"
                      size="small"
                      fullWidth
                      value={status.value}
                      className="input-white text-white"
                      onChange={(e) =>
                        setStatus({
                          ...status,
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </form>
              <MDBModalFooter className="d-flex justify-content-between align-items-center mt-3 pb-0">
                <MDBBtn
                  outline
                  color="dark"
                  type="button"
                  className="border-white text-white"
                  onClick={() => setVaryingModal(false)}
                >
                  Відхилити
                </MDBBtn>
                <MDBBtn
                  type="button"
                  color="warning"
                  className="mx-2"
                  onClick={async function(event) {
                    event.preventDefault();
                    let res = await SaveStatusService(status);
                    toggleShow();
                    showAlert(res);
                    setTimeout(function() {
                      setLoading(true);
                    }, 1000);
                  }}
                >
                  Зберегти
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
