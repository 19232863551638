import axios from "axios";
import getHostName from "../config/config";

export const FolderService = {
  createFolder,
  deleteFolder,
  getFolderTree,
  updateFolder,
  getFolder,
};

async function getFolder(id) {
  try {
    const response = await axios.get(
      getHostName() + "/api/v1/folder/get-folder/?folderId=" + id
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
}

async function getFolderTree() {
  try {
    const response = await axios.get(
      getHostName() + "/api/v1/folder/get-folder-tree"
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
}

async function createFolder(folder) {
  try {
    const response = await axios.post(
      getHostName() + "/api/v1/folder/create-folder/",
      folder
    );
    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
}

async function updateFolder(folder) {
  try {
    const response = await axios.post(
      getHostName() + "/api/v1/folder/update-folder/",
      folder
    );
    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
}

async function deleteFolder(id) {
  try {
    const response = await axios.delete(
      "https://" +
        getHostName() +
        "/api/v1/folder/delete-folder/?folderId=" +
        id
    );
    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
}
